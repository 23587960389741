import storage from 'local-storage-fallback';
import { is } from 'typia';
import { GalleryColorizeBy } from './actions/galleryActions.js';
import {
  authInitialState,
  authReducer,
  AuthState,
} from './reducers/authReducer.js';
import { changesetReducer } from './reducers/changesetsReducer.js';
import { drawingLinesReducer } from './reducers/drawingLinesReducer.js';
import { drawingPointsReducer } from './reducers/drawingPointsReducer.js';
import { elevationChartReducer } from './reducers/elevationChartReducer.js';
import {
  galleryInitialState,
  galleryReducer,
} from './reducers/galleryReducer.js';
import {
  l10nInitialState,
  l10nReducer,
  L10nState,
} from './reducers/l10nReducer.js';
import {
  mainInitialState,
  mainReducer,
  MainState,
} from './reducers/mainReducer.js';
import { mapDetailsReducer } from './reducers/mapDetailsReducer.js';
import {
  mapInitialState,
  mapReducer,
  MapState,
} from './reducers/mapReducer.js';
import { mapsReducer } from './reducers/mapsReducer.js';
import {
  objectInitialState,
  objectsReducer,
  ObjectsState,
} from './reducers/objectsReducer.js';
import {
  routePlannerInitialState,
  routePlannerReducer,
  RoutePlannerState,
} from './reducers/routePlannerReducer.js';
import { searchReducer } from './reducers/searchReducer.js';
import { toastsReducer } from './reducers/toastsReducer.js';
import { trackingReducer } from './reducers/trackingReducer.js';
import {
  trackViewerInitialState,
  trackViewerReducer,
  TrackViewerState,
} from './reducers/trackViewerReducer.js';
import { websocketReducer } from './reducers/websocketReducer.js';
import { wikiReducer } from './reducers/wikiReducer.js';
import { RootState } from './store.js';
import { transportTypeDefs } from './transportTypeDefs.js';

export const reducers = {
  auth: authReducer,
  changesets: changesetReducer,
  drawingLines: drawingLinesReducer,
  drawingPoints: drawingPointsReducer,
  elevationChart: elevationChartReducer,
  gallery: galleryReducer,
  l10n: l10nReducer,
  main: mainReducer,
  mapDetails: mapDetailsReducer,
  map: mapReducer,
  objects: objectsReducer,
  routePlanner: routePlannerReducer,
  search: searchReducer,
  toasts: toastsReducer,
  tracking: trackingReducer,
  trackViewer: trackViewerReducer,
  websocket: websocketReducer,
  maps: mapsReducer,
  wiki: wikiReducer,
};

export function getInitialState() {
  let persisted: Partial<Record<keyof RootState, unknown>>;

  try {
    persisted = JSON.parse(storage.getItem('store') ?? '{}');
  } catch {
    persisted = {};
  }

  const initial: Partial<RootState> = {};

  if (is<Partial<MapState>>(persisted.map)) {
    initial.map = { ...mapInitialState, ...persisted.map };
  }

  if (is<Partial<L10nState>>(persisted.l10n)) {
    initial.l10n = { ...l10nInitialState, ...persisted.l10n };
  }

  if (is<Partial<AuthState>>(persisted.auth)) {
    initial.auth = { ...authInitialState, ...persisted.auth };
  }

  if (is<Partial<MainState>>(persisted.main)) {
    initial.main = { ...mainInitialState, ...persisted.main };
  }

  if (is<Partial<ObjectsState>>(persisted.objects)) {
    initial.objects = { ...objectInitialState, ...persisted.objects };
  }

  if (is<Partial<RoutePlannerState>>(persisted.routePlanner)) {
    initial.routePlanner = {
      ...routePlannerInitialState,
      ...persisted.routePlanner,
    };
  }

  if (is<Partial<TrackViewerState>>(persisted.trackViewer)) {
    initial.trackViewer = {
      ...trackViewerInitialState,
      ...persisted.trackViewer,
    };
  }

  if (
    is<{ colorizeBy?: GalleryColorizeBy | null; recentTags?: string[] }>(
      persisted.gallery,
    )
  ) {
    initial.gallery = {
      ...galleryInitialState,
      ...persisted.gallery,
    };
  }

  const tt = initial.routePlanner?.transportType;

  if (initial.routePlanner && tt && !transportTypeDefs[tt]) {
    initial.routePlanner.transportType = 'hiking';
  }

  return initial;
}
