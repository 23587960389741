import {
  routePlannerSetFinish,
  routePlannerSetFromCurrentPosition,
  routePlannerSetStart,
} from '../actions/routePlannerActions.js';
import { getCurrentPosition } from '../geoutils.js';
import { Processor } from '../middlewares/processorMiddleware.js';

export const routePlannerSetFromCurrentPositionProcessor: Processor<
  typeof routePlannerSetFromCurrentPosition
> = {
  actionCreator: routePlannerSetFromCurrentPosition,
  errorKey: 'routePlanner.gpsError',
  handle: async ({ dispatch, action }) => {
    const { lat, lon } = await getCurrentPosition();

    if (action.payload === 'start') {
      dispatch(routePlannerSetStart({ start: { lat, lon } }));
    } else {
      dispatch(routePlannerSetFinish({ finish: { lat, lon } }));
    }
  },
};
