import aerodrome from '../images/poiIcons/aerodrome.svg';
import alpine_hut from '../images/poiIcons/alpine_hut.svg';
import apartment from '../images/poiIcons/apartment.svg';
import arch from '../images/poiIcons/arch.svg';
import archaeological_site from '../images/poiIcons/archaeological_site.svg';
import arts_centre from '../images/poiIcons/arts_centre.svg';
import artwork from '../images/poiIcons/artwork.svg';
import atm from '../images/poiIcons/atm.svg';
import attraction from '../images/poiIcons/attraction.svg';
import bank from '../images/poiIcons/bank.svg';
import bar from '../images/poiIcons/bar.svg';
import basic_hut from '../images/poiIcons/basic_hut.svg';
import bbq from '../images/poiIcons/bbq.svg';
import beach_resort from '../images/poiIcons/beach_resort.svg';
import beehive from '../images/poiIcons/beehive.svg';
import bell_tower from '../images/poiIcons/bell_tower.svg';
import bench from '../images/poiIcons/bench.svg';
import bicycle_parking from '../images/poiIcons/bicycle_parking.svg';
import bicycle_repair_station from '../images/poiIcons/bicycle_repair_station.svg';
import biergarten from '../images/poiIcons/biergarten.svg';
import bird_hide from '../images/poiIcons/bird_hide.svg';
import board from '../images/poiIcons/board.svg';
import boat_rental from '../images/poiIcons/boat_rental.svg';
import boundary_stone from '../images/poiIcons/boundary_stone.svg';
import building from '../images/poiIcons/building.svg';
import bunker from '../images/poiIcons/bunker.svg';
import bureau_de_change from '../images/poiIcons/bureau_de_change.svg';
import bus_station from '../images/poiIcons/bus_station.svg';
import bus_stop from '../images/poiIcons/bus_stop.svg';
import bust from '../images/poiIcons/bust.svg';
import cafe from '../images/poiIcons/cafe.svg';
import camp_site from '../images/poiIcons/camp_site.svg';
import car_wash from '../images/poiIcons/car_wash.svg';
import casino from '../images/poiIcons/casino.svg';
import castle from '../images/poiIcons/castle.svg';
import cattle_grid from '../images/poiIcons/cattle_grid.svg';
import cave_entrance from '../images/poiIcons/cave_entrance.svg';
import chalet from '../images/poiIcons/chalet.svg';
import charging_station from '../images/poiIcons/charging_station.svg';
import chimney from '../images/poiIcons/chimney.svg';
import church from '../images/poiIcons/church.svg';
import cinema from '../images/poiIcons/cinema.svg';
import city_gate from '../images/poiIcons/city_gate.svg';
import community_centre from '../images/poiIcons/community_centre.svg';
import courthouse from '../images/poiIcons/courthouse.svg';
import cross from '../images/poiIcons/cross.svg';
import cycle_barrier from '../images/poiIcons/cycle_barrier.svg';
import dentist from '../images/poiIcons/dentist.svg';
import doctors from '../images/poiIcons/doctors.svg';
import drinking_spring from '../images/poiIcons/drinking_spring.svg';
import drinking_water from '../images/poiIcons/drinking_water.svg';
import dyke from '../images/poiIcons/dyke.svg';
import earth_bank from '../images/poiIcons/earth_bank.svg';
import college from '../images/poiIcons/education/college.svg';
import kindergarten from '../images/poiIcons/education/nursery.svg';
import school from '../images/poiIcons/education/school.svg';
import elevator from '../images/poiIcons/elevator.svg';
import embankment from '../images/poiIcons/embankment.svg';
import emergency_phone from '../images/poiIcons/emergency_phone.svg';
import excrement_bags from '../images/poiIcons/excrement_bags.svg';
import fast_food from '../images/poiIcons/fast_food.svg';
import fell from '../images/poiIcons/fell.svg';
import ferry from '../images/poiIcons/ferry.svg';
import fire_station from '../images/poiIcons/fire_station.svg';
import firepit from '../images/poiIcons/firepit.svg';
import fishing from '../images/poiIcons/fishing.svg';
import fixme from '../images/poiIcons/fixme.svg';
import ford from '../images/poiIcons/ford.svg';
import foresters_lodge from "../images/poiIcons/forester's_lodge.svg";
import fort from '../images/poiIcons/fort.svg';
import fortress from '../images/poiIcons/fortress.svg';
import fountain from '../images/poiIcons/fountain.svg';
import free_flying from '../images/poiIcons/free_flying.svg';
import fuel from '../images/poiIcons/fuel.svg';
import gate from '../images/poiIcons/gate.svg';
import golf from '../images/poiIcons/golf.svg';
import cemetery from '../images/poiIcons/grave.svg';
import guest_house from '../images/poiIcons/guest_house.svg';
import guidepost from '../images/poiIcons/guidepost.svg';
import gully from '../images/poiIcons/gully.svg';
import horse_riding from '../images/poiIcons/horse_riding.svg';
import hospital from '../images/poiIcons/hospital.svg';
import hostel from '../images/poiIcons/hostel.svg';
import hot_spring from '../images/poiIcons/hot_spring.svg';
import hotel from '../images/poiIcons/hotel.svg';
import hunting_stand from '../images/poiIcons/hunting_stand.svg';
import hut from '../images/poiIcons/hut.svg';
import ice_cream from '../images/poiIcons/ice_cream.svg';
import internet_cafe from '../images/poiIcons/internet_cafe.svg';
import kissing_gate from '../images/poiIcons/kissing_gate.svg';
import lean_to from '../images/poiIcons/lean_to.svg';
import library from '../images/poiIcons/library.svg';
import lift_gate from '../images/poiIcons/lift_gate.svg';
import lighthouse from '../images/poiIcons/lighthouse.svg';
import manger from '../images/poiIcons/manger.svg';
import manor from '../images/poiIcons/manor.svg';
import map from '../images/poiIcons/map.svg';
import mast from '../images/poiIcons/mast_other.svg';
import memorial from '../images/poiIcons/memorial.svg';
import memorial_stone from '../images/poiIcons/memorial_stone.svg';
import mine from '../images/poiIcons/mine.svg';
import miniature_golf from '../images/poiIcons/miniature_golf.svg';
import monument from '../images/poiIcons/monument.svg';
import motel from '../images/poiIcons/motel.svg';
import motorcycle_barrier from '../images/poiIcons/motorcycle_barrier.svg';
import motorcycle_parking from '../images/poiIcons/motorcycle_parking.svg';
import museum from '../images/poiIcons/museum.svg';
import nightclub from '../images/poiIcons/nightclub.svg';
import not_drinking_spring from '../images/poiIcons/not_drinking_spring.svg';
import obelisk from '../images/poiIcons/obelisk.svg';
import office from '../images/poiIcons/office.svg';
import outdoor_seating from '../images/poiIcons/outdoor_seating.svg';
import palace from '../images/poiIcons/palace.svg';
import parking from '../images/poiIcons/parking.svg';
import parking_tickets from '../images/poiIcons/parking_tickets.svg';
import peak from '../images/poiIcons/peak.svg';
import pharmacy from '../images/poiIcons/pharmacy.svg';
import picnic_shelter from '../images/poiIcons/picnic_shelter.svg';
import picnic_table from '../images/poiIcons/picnic_table.svg';
import place_of_worship from '../images/poiIcons/place_of_worship.svg';
import plaque from '../images/poiIcons/plaque.svg';
import playground from '../images/poiIcons/playground.svg';
import police from '../images/poiIcons/police.svg';
import post_box from '../images/poiIcons/post_box.svg';
import post_office from '../images/poiIcons/post_office.svg';
import prison from '../images/poiIcons/prison.svg';
import pub from '../images/poiIcons/pub.svg';
import public_bath from '../images/poiIcons/public_bath.svg';
import public_bookcase from '../images/poiIcons/public_bookcase.svg';
import public_transport from '../images/poiIcons/public_transport.svg';
import public_transport_tickets from '../images/poiIcons/public_transport_tickets.svg';
import recycling from '../images/poiIcons/recycling.svg';
import refitted_drinking_spring from '../images/poiIcons/refitted_drinking_spring.svg';
import refitted_not_drinking_spring from '../images/poiIcons/refitted_not_drinking_spring.svg';
import refitted_spring from '../images/poiIcons/refitted_spring.svg';
import rental_bicycle from '../images/poiIcons/rental_bicycle.svg';
import rental_car from '../images/poiIcons/rental_car.svg';
import restaurant from '../images/poiIcons/restaurant.svg';
import rock from '../images/poiIcons/rock.svg';
import ruins from '../images/poiIcons/ruins.svg';
import saddle from '../images/poiIcons/saddle.svg';
import sauna from '../images/poiIcons/sauna.svg';
import shelter from '../images/poiIcons/shelter.svg';
import shop_alcohol from '../images/poiIcons/shop/alcohol.svg';
import shop_art from '../images/poiIcons/shop/art.svg';
import shop_bag from '../images/poiIcons/shop/bag.svg';
import shop_bakery from '../images/poiIcons/shop/bakery.svg';
import shop_beauty from '../images/poiIcons/shop/beauty.svg';
import shop_bed from '../images/poiIcons/shop/bed.svg';
import shop_beverages from '../images/poiIcons/shop/beverages.svg';
import shop_bicycle from '../images/poiIcons/shop/bicycle.svg';
import books from '../images/poiIcons/shop/book.svg';
import shop_bookmaker from '../images/poiIcons/shop/bookmaker.svg';
import shop_butcher from '../images/poiIcons/shop/butcher.svg';
import shop_car from '../images/poiIcons/shop/car.svg';
import shop_car_parts from '../images/poiIcons/shop/car_parts.svg';
import shop_car_repair from '../images/poiIcons/shop/car_repair.svg';
import shop_carpet from '../images/poiIcons/shop/carpet.svg';
import shop_charity from '../images/poiIcons/shop/charity.svg';
import shop_chemist from '../images/poiIcons/shop/chemist.svg';
import shop_clothes from '../images/poiIcons/shop/clothes.svg';
import shop_coffee from '../images/poiIcons/shop/coffee.svg';
import shop_computer from '../images/poiIcons/shop/computer.svg';
import shop_confectionery from '../images/poiIcons/shop/confectionery.svg';
import shop_convenience from '../images/poiIcons/shop/convenience.svg';
import shop_copyshop from '../images/poiIcons/shop/copyshop.svg';
import shop_dairy from '../images/poiIcons/shop/dairy.svg';
import shop_deli from '../images/poiIcons/shop/deli.svg';
import shop_department_store from '../images/poiIcons/shop/department_store.svg';
import shop_diy from '../images/poiIcons/shop/diy.svg';
import shop_electronics from '../images/poiIcons/shop/electronics.svg';
import shop_fabric from '../images/poiIcons/shop/fabric.svg';
import shop_florist from '../images/poiIcons/shop/florist.svg';
import shop_furniture from '../images/poiIcons/shop/furniture.svg';
import shop_garden_centre from '../images/poiIcons/shop/garden_centre.svg';
import shop_gift from '../images/poiIcons/shop/gift.svg';
import shop_greengrocer from '../images/poiIcons/shop/greengrocer.svg';
import shop_hairdresser from '../images/poiIcons/shop/hairdresser.svg';
import shop_hifi from '../images/poiIcons/shop/hifi.svg';
import shop_houseware from '../images/poiIcons/shop/houseware.svg';
import shop_interior_decoration from '../images/poiIcons/shop/interior_decoration.svg';
import shop_jewelry from '../images/poiIcons/shop/jewelry.svg';
import shop_laundry from '../images/poiIcons/shop/laundry.svg';
import shop_marketplace from '../images/poiIcons/shop/marketplace.svg';
import shop_massage from '../images/poiIcons/shop/massage.svg';
import shop_medical_supply from '../images/poiIcons/shop/medical_supply.svg';
import shop_mobile_phone from '../images/poiIcons/shop/mobile_phone.svg';
import shop_music from '../images/poiIcons/shop/music.svg';
import shop_musical_instrument from '../images/poiIcons/shop/musical_instrument.svg';
import shop_newsagent from '../images/poiIcons/shop/newsagent.svg';
import shop_optician from '../images/poiIcons/shop/optician.svg';
import shop_outdoor from '../images/poiIcons/shop/outdoor.svg';
import shop_paint from '../images/poiIcons/shop/paint.svg';
import shop_perfumery from '../images/poiIcons/shop/perfumery.svg';
import shop_pet from '../images/poiIcons/shop/pet.svg';
import shop_photo from '../images/poiIcons/shop/photo.svg';
import shop_seafood from '../images/poiIcons/shop/seafood.svg';
import shop_second_hand from '../images/poiIcons/shop/second_hand.svg';
import shop_shoes from '../images/poiIcons/shop/shoes.svg';
import shop_sports from '../images/poiIcons/shop/sports.svg';
import shop_stationery from '../images/poiIcons/shop/stationery.svg';
import shop_supermarket from '../images/poiIcons/shop/supermarket.svg';
import shop_tea from '../images/poiIcons/shop/tea.svg';
import shop_ticket from '../images/poiIcons/shop/ticket.svg';
import shop_tobacco from '../images/poiIcons/shop/tobacco.svg';
import shop_toys from '../images/poiIcons/shop/toys.svg';
import shop_trade from '../images/poiIcons/shop/trade.svg';
import shop_travel_agency from '../images/poiIcons/shop/travel_agency.svg';
import shop_tyres from '../images/poiIcons/shop/tyres.svg';
import shop_variety_store from '../images/poiIcons/shop/variety_store.svg';
import vending_machine from '../images/poiIcons/shop/vending_machine.svg';
import shop_video from '../images/poiIcons/shop/video.svg';
import shop_video_games from '../images/poiIcons/shop/video_games.svg';
import shower from '../images/poiIcons/shower.svg';
import sinkhole from '../images/poiIcons/sinkhole.svg';
import social_facility from '../images/poiIcons/social_facility.svg';
import archery from '../images/poiIcons/sport/archery.svg';
import baseball from '../images/poiIcons/sport/baseball.svg';
import diving from '../images/poiIcons/sport/diving.svg';
import gym from '../images/poiIcons/sport/gym.svg';
import gymnastics from '../images/poiIcons/sport/gymnasium.svg';
import climbing from '../images/poiIcons/sport/hillclimbing.svg';
import skating from '../images/poiIcons/sport/iceskating.svg';
import motor from '../images/poiIcons/sport/motorracing.svg';
import ski from '../images/poiIcons/sport/skiing_crosscountry.svg';
import skiing from '../images/poiIcons/sport/skiing_downhill.svg';
import soccer from '../images/poiIcons/sport/soccer.svg';
import swimming from '../images/poiIcons/sport/swimming_outdoor.svg';
import tennis from '../images/poiIcons/sport/tennis.svg';
import spring from '../images/poiIcons/spring.svg';
import statue from '../images/poiIcons/statue.svg';
import stile from '../images/poiIcons/stile.svg';
import stone from '../images/poiIcons/stone.svg';
import taxi from '../images/poiIcons/taxi.svg';
import telephone from '../images/poiIcons/telephone.svg';
import theatre from '../images/poiIcons/theatre.svg';
import toilets from '../images/poiIcons/toilets.svg';
import toll_booth from '../images/poiIcons/toll_booth.svg';
import tower_communication from '../images/poiIcons/tower_communication.svg';
import tower_observation from '../images/poiIcons/tower_observation.svg';
import tower_other from '../images/poiIcons/tower_other.svg';
import townhall from '../images/poiIcons/townhall.svg';
import traffic_signals from '../images/poiIcons/traffic_signals.svg';
import tree from '../images/poiIcons/tree.svg';
import tree2 from '../images/poiIcons/tree2.svg';
import tree_protected from '../images/poiIcons/tree_protected.svg';
import university from '../images/poiIcons/university.svg';
import vehicle_inspection from '../images/poiIcons/vehicle_inspection.svg';
import veterinary from '../images/poiIcons/veterinary.svg';
import viewpoint from '../images/poiIcons/viewpoint.svg';
import waste_basket from '../images/poiIcons/waste_basket.svg';
import waste_disposal from '../images/poiIcons/waste_disposal.svg';
import water_park from '../images/poiIcons/water_park.svg';
import water_tower from '../images/poiIcons/water_tower.svg';
import water_well from '../images/poiIcons/water_well.svg';
import water_works from '../images/poiIcons/water_works.svg';
import waterfall from '../images/poiIcons/waterfall.svg';
import watering_place from '../images/poiIcons/watering_place.svg';
import wayside_shrine from '../images/poiIcons/wayside_shrine.svg';
import weather_shelter from '../images/poiIcons/weather_shelter.svg';
import wilderness_hut from '../images/poiIcons/wilderness_hut.svg';
import zoo from '../images/poiIcons/zoo.svg';
import { Node } from './types.js';

// hack to have only single icon for churches...
const buildingMapping = { '*': building, hut, church, chapel: church };

export const osmTagToIconMapping: Node = {
  aeroway: {
    aerodrome,
  },
  amenity: {
    atm,
    arts_centre,
    bank,
    bar,
    bbq,
    bench,
    bicycle_parking,
    bicycle_repair_station,
    bicycle_rental: rental_bicycle,
    biergarten,
    boat_rental,
    bureau_de_change,
    bus_station,
    cafe,
    car_rental: rental_car,
    car_wash,
    casino,
    charging_station,
    cinema,
    clinic: doctors,
    college,
    community_centre,
    courthouse,
    dentist,
    doctors,
    drinking_water,
    emergency_phone,
    excrement_bags,
    fast_food,
    feeding_place: manger,
    game_feeding: manger,
    ferry,
    fire_station,
    fountain,
    fuel,
    hospital,
    hunting_stand,
    ice_cream,
    internet_cafe,
    kindergarten,
    library,
    motorcycle_parking,
    nightclub,
    parking,
    pharmacy,
    place_of_worship: {
      '*': place_of_worship,
      building: buildingMapping,
    },
    police,
    post_box,
    post_office,
    prison,
    pub,
    public_bath,
    public_bookcase,
    recycling,
    restaurant,
    school,
    shelter: {
      '*': shelter,
      shelter_type: {
        basic_hut,
        lean_to,
        picnic_shelter,
        public_transport,
        weather_shelter,
      },
    },
    shower,
    ski_rental: skiing,
    social_facility,
    taxi,
    telephone,
    theatre,
    toilets,
    townhall,
    university,
    vehicle_inspection,
    vending_machine: {
      '*': vending_machine,
      vending: {
        parking_tickets,
        public_transport_tickets,
      },
    },
    veterinary,
    waste_basket,
    waste_disposal,
    watering_place,
  },
  barrier: {
    cycle_barrier,
    gate,
    kissing_gate,
    lift_gate,
    motorcycle_barrier,
    stile,
    toll_booth,
    cattle_grid,
  },
  highway: { bus_stop, elevator, ford, traffic_signals },
  historic: {
    archaeological_site,
    boundary_stone,
    castle: {
      '*': castle,
      castle_type: {
        fortress,
        palace,
      },
    },
    city_gate,
    fort,
    manor,
    memorial: {
      '*': memorial,
      memorial: {
        bust,
        plaque,
        statue,
        stone: memorial_stone,
      },
    },
    mine,
    mine_shaft: mine,
    monument,
    obelisk,
    wayside_cross: cross,
    wayside_shrine,
  },
  landuse: {
    cemetery,
    industrial: { industrial: mine },
  },
  leisure: {
    beach_resort,
    bird_hide,
    firepit,
    fishing,
    horse_riding,
    golf_course: golf,
    miniature_golf,
    outdoor_seating,
    picnic_table,
    playground,
    sauna,
    swimming_pool: water_park,
    water_park,
  },
  man_made: {
    adit: mine,
    beehive,
    chimney,
    cross,
    dyke,
    embankment,
    foresters_lodge,
    lighthouse,
    mast,
    mineshaft: mine,
    reservoir_covered: water_works,
    tower: {
      'tower:type': {
        '*': tower_other,
        bell_tower,
        communication: tower_communication,
        observation: tower_observation,
      },
    },
    water_tower,
    water_well,
    water_works,
  },
  millitary: {
    bunker,
  },
  natural: {
    arch,
    cave_entrance,
    earth_bank,
    fell,
    gully,
    hot_spring,
    peak,
    rock,
    saddle,
    sinkhole,
    spring: {
      '*': spring,
      drinking_water: {
        no: not_drinking_spring,
        yes: drinking_spring,
      },
      refitted: {
        yes: {
          '*': refitted_spring,
          drinking_water: {
            no: refitted_not_drinking_spring,
            yes: refitted_drinking_spring,
          },
        },
      },
    },
    stone,
    tree: {
      '*': tree2,
      denotation: {
        natural_monument: tree,
      },
      protected: {
        yes: tree_protected,
      },
    },
  },
  route: {
    horse: horse_riding,
    ski,
  },
  ruins: {
    '*': ruins,
  },
  shop: {
    alcohol: shop_alcohol,
    books,
    art: shop_art,
    bag: shop_bag,
    bakery: shop_bakery,
    beauty: shop_beauty,
    bed: shop_bed,
    beverages: shop_beverages,
    bicycle: shop_bicycle,
    bookmaker: shop_bookmaker,
    butcher: shop_butcher,
    car_parts: shop_car_parts,
    carpet: shop_carpet,
    car_repair: shop_car_repair,
    car: shop_car,
    charity: shop_charity,
    chemist: shop_chemist,
    clothes: shop_clothes,
    coffee: shop_coffee,
    computer: shop_computer,
    confectionery: shop_confectionery,
    convenience: shop_convenience,
    copyshop: shop_copyshop,
    dairy: shop_dairy,
    deli: shop_deli,
    department_store: shop_department_store,
    diy: shop_diy,
    electronics: shop_electronics,
    fabric: shop_fabric,
    florist: shop_florist,
    furniture: shop_furniture,
    garden_centre: shop_garden_centre,
    gift: shop_gift,
    greengrocer: shop_greengrocer,
    hairdresser: shop_hairdresser,
    hifi: shop_hifi,
    houseware: shop_houseware,
    ice_cream,
    interior_decoration: shop_interior_decoration,
    jewelry: shop_jewelry,
    laundry: shop_laundry,
    marketplace: shop_marketplace,
    massage: shop_massage,
    medical_supply: shop_medical_supply,
    mobile_phone: shop_mobile_phone,
    musical_instrument: shop_musical_instrument,
    music: shop_music,
    newsagent: shop_newsagent,
    optician: shop_optician,
    outdoor: shop_outdoor,
    paint: shop_paint,
    perfumery: shop_perfumery,
    pet: shop_pet,
    photo: shop_photo,
    seafood: shop_seafood,
    second_hand: shop_second_hand,
    shoes: shop_shoes,
    sports: shop_sports,
    stationery: shop_stationery,
    supermarket: shop_supermarket,
    tea: shop_tea,
    ticket: shop_ticket,
    tobacco: shop_tobacco,
    toys: shop_toys,
    trade: shop_trade,
    travel_agency: shop_travel_agency,
    tyres: shop_tyres,
    variety_store: shop_variety_store,
    video_games: shop_video_games,
    video: shop_video,
  },
  sport: {
    archery,
    baseball,
    climbing,
    cycling: shop_bicycle,
    diving,
    equestrian: horse_riding,
    free_flying,
    golf,
    gym,
    gymnastics,
    horse_racing: horse_riding,
    motor,
    skating,
    ski_jumping: skiing,
    skiing,
    soccer,
    swimming,
    tennis,
  },
  tourism: {
    alpine_hut,
    apartment,
    artwork: {
      '*': artwork,
      artwork_type: {
        bust,
      },
    },
    attraction,
    camp_site,
    chalet,
    guest_house,
    hostel,
    hotel,
    information: {
      information: {
        board,
        guidepost,
        map,
        office,
      },
    },
    motel,
    museum,
    picnic_site: picnic_table,
    viewpoint,
    wilderness_hut,
    zoo,
  },
  waterway: {
    waterfall,
  },

  // NOTE keep at the end
  building: buildingMapping,
  fixme: {
    '*': fixme,
  },
};
